import {Component} from 'react';
import {ethers} from 'ethers';
import Contract from './abi/LikeABrush.json';
import {WinterCheckout} from '@usewinter/checkout';

import './App.css';


const {MerkleTree} = require('merkletreejs');
const keccak256 = require('keccak256');
const freelist = require('./lists/freelist.json');
const freelist_iron = require('./lists/freelist_iron.json');
const freelist_bronze = require('./lists/freelist_bronze.json');
const freelist_silver = require('./lists/freelist_silver.json');
const freelist_gold = require('./lists/freelist_gold.json');
const freelist_platinum = require('./lists/freelist_platinum.json');
const whitelist = require('./lists/whitelist.json');
const oglist = require('./lists/oglist.json');
const allowList = require('./lists/allowlist.json');

const WLSaleDate = 1663675200000;
const ALSaleDate = 1663696800000;
const SaleDate = 1663700400000;
const EndSaleDate = 1663711200000;

const collectionURL = "https://opensea.io/collection/like-a-brush-by-julien-durix";
const address = "0x6b6c66c7128f2585d8aad8e4efdac33dd3c376cc";
const winterIds = [7092, 7092];
const networkId = "0x1";

class Home extends Component {

    constructor(props) {
        super(props);
        this.handleMintClick = this.handleMintClick.bind(this);
        this.handleWhitelistMintClick = this.handleWhitelistMintClick.bind(this);
        this.handleAllowlistMintClick = this.handleAllowlistMintClick.bind(this);
        this.handleFreeMintClick = this.handleFreeMintClick.bind(this);
        this.handleMetamaskClick = this.handleMetamaskClick.bind(this);
        this.handleCollectionClick = this.handleCollectionClick.bind(this);
        this.handleCardClick = this.handleCardClick.bind(this);
        this.openLegals = this.openLegals.bind(this);
        this.upAction = this.upAction.bind(this);
        this.downAction = this.downAction.bind(this);
        this.FMdownAction = this.FMdownAction.bind(this);
        this.FMupAction = this.FMupAction.bind(this);
        this.refreshState = this.refreshState.bind(this);
        let addr = null;
        if (typeof window.ethereum !== 'undefined') {
            addr = window.ethereum.selectedAddress;
        }
        this.state = {
            winterId: winterIds[0],
            selectedAddress: addr,
            counter: 1,
            maxCounter: null,
            FMcounter: 1,
            FMmaxCounter: null,
            mintingStatus: 0,
            mintError: null
        };
    }

    componentDidMount() {
        this.refreshState();
    }

    refreshPage() {
        location.reload();
    }

    PRICE(number) {
        let p = number * 0.28;
        return ethers.utils.parseEther(p.toFixed(2).toString());
    }

    PRESALE_PRICE(number) {
        let p = number * 0.23;
        return ethers.utils.parseEther(p.toFixed(2).toString());
    }

    FREE_PRICE() {
        return ethers.utils.parseEther("0");
    }

    increaseGasLimit(estimatedGasLimit) {
        return estimatedGasLimit.mul(115).div(100) // increase by 15%
    }

    readableError(raw) {
        var rs = raw.toString();
        var ind1 = rs.indexOf('{"code"');
        var ind2 = rs.indexOf('}, method="estimateGas');
        var newStr = rs.substring(ind1, ind2 + 1);
        try {
            var jErr = JSON.parse(newStr);
            return jErr.message;
        } catch (e) {
            return raw.message;
        }
    }

    checkNetwork(provider) {
        if (provider.provider.chainId !== networkId) {
            provider.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: networkId}],
            }).then(() => {
                this.refreshPage();
            });
            return false;
        } else {
            return true;
        }
    }

    async refreshState() {
        if (this.state.selectedAddress) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            if (this.checkNetwork(provider)) {
                this.computeMax();
            }
        }
    }

    upAction() {
        if (this.state.maxCounter) {
            const count = Math.min(this.state.counter + 1, this.state.maxCounter);
            this.setState({counter: count});
        } else {
            this.setState({counter: 1});
        }
    }

    downAction() {
        const count = Math.max(1, this.state.counter - 1);
        this.setState({counter: count});
    }

    FMupAction() {
        if (this.state.FMmaxCounter) {
            const count = Math.min(this.state.FMcounter + 1, this.state.FMmaxCounter);
            this.setState({FMcounter: count});
        } else {
            this.setState({FMcounter: 1});
        }
    }

    FMdownAction() {
        const count = Math.max(1, this.state.FMcounter - 1);
        this.setState({FMcounter: count});
    }

    computeMax() {
        if (!this.state.maxCounter) {
            const now = new Date().getTime();
            if (now < ALSaleDate) {
                this.setState({maxCounter: 2});
            } else if (now < SaleDate) {
                this.setState({maxCounter: 1});
            } else {
                this.setState({maxCounter: 99});
            }
        }
        if (!this.state.FMmaxCounter) {
            const addrToCheck = this.state.selectedAddress;

            if (this.isAllowed(addrToCheck, freelist_platinum)) {
                this.setState({FMmaxCounter: 38});
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_gold)) {
                this.setState({FMmaxCounter: 20});
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_silver)) {
                this.setState({FMmaxCounter: 15});
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_bronze)) {
                this.setState({FMmaxCounter: 3});
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_iron)) {
                this.setState({FMmaxCounter: 2});
                return;
            }

            if (this.isAllowed(addrToCheck, freelist)) {
                this.setState({FMmaxCounter: 1});
            }
        }
    }

    handleMintClick() {
        this.mint();
    }

    handleFreeMintClick() {
        this.freeMint();
    }

    handleCollectionClick() {
        window.location.href = collectionURL;
    }

    openLegals(){
        window.open('https://mint.juliendurixnft.io/legals.html', '_blank').focus();
    }

    async handleWhitelistMintClick() {
        let overrides = {
            value: this.PRESALE_PRICE(this.state.counter)
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const addrToCheck = this.state.selectedAddress;
        if (this.checkNetwork(provider)) {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(address, Contract.abi, signer);

            if (this.isAllowed(addrToCheck, oglist)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(oglist);
                    const gasL = await contract.estimateGas.ogMint(this.state.counter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.ogMint(this.state.counter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
                return;
            }

            if (this.isAllowed(addrToCheck, whitelist)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(whitelist);
                    const gasL = await contract.estimateGas.whitelistMint(this.state.counter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.whitelistMint(this.state.counter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
            }
        }
    }


    async handleAllowlistMintClick() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if (this.checkNetwork(provider)) {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(address, Contract.abi, signer);
            const proof = this.proofFromList(allowList);

            try {
                this.setState({mintingStatus: 1, mintError: null});
                let overrides = {
                    value: this.PRESALE_PRICE(this.state.counter)
                }
                const gasL = await contract.estimateGas.allowlistMint(proof, overrides);
                overrides.gasLimit = this.increaseGasLimit(gasL);
                const transaction = await contract.allowlistMint(proof, overrides);
                await transaction.wait();
                this.setState({mintingStatus: 2, mintError: null});
            } catch (err) {
                this.setState({mintingStatus: 3, mintError: this.readableError(err)});
            }
        }
    }

    handleCardClick() {
        let twinterId;
        const now = new Date().getTime();
        if (now < ALSaleDate) {
            twinterId = winterIds[0]
        } else {
            twinterId = winterIds[1]
        }
        this.setState({winterId: twinterId, showWinter: true, mintingStatus: 1, mintError: null});
    }

    handleMetamaskClick() {
        this.requestAccount();
    }

    isAllowed(address, list) {
        const tab = [];
        list.map(a => {
            tab.push(a.address)
        });

        const leaves = tab.map(v => keccak256(v));
        const tree = new MerkleTree(leaves, keccak256, {sort: true});
        const leaf = keccak256(address);
        const proof = tree.getHexProof(leaf);
        return tree.verify(proof, leaf, tree.getHexRoot());
    }

    async requestAccount() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
            this.setState({selectedAddress: accounts[0]});
            this.refreshState();
        }
    }

    async freeMint() {
        let overrides = {
            value: this.FREE_PRICE()
        }
        const addrToCheck = this.state.selectedAddress;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if (this.checkNetwork(provider)) {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(address, Contract.abi, signer);

            if (this.isAllowed(addrToCheck, freelist_platinum)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(freelist_platinum);
                    const gasL = await contract.estimateGas.freeMintPlatinum(this.state.FMcounter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.freeMintPlatinum(this.state.FMcounter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_gold)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(freelist_gold);
                    const gasL = await contract.estimateGas.freeMintGold(this.state.FMcounter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.freeMintGold(this.state.FMcounter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_silver)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(freelist_silver);
                    const gasL = await contract.estimateGas.freeMintSilver(this.state.FMcounter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.freeMintSilver(this.state.FMcounter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_bronze)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(freelist_bronze);
                    const gasL = await contract.estimateGas.freeMintBronze(this.state.FMcounter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.freeMintBronze(this.state.FMcounter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
                return;
            }

            if (this.isAllowed(addrToCheck, freelist_iron)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(freelist_iron);
                    const gasL = await contract.estimateGas.freeMintIron(this.state.FMcounter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.freeMintIron(this.state.FMcounter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
                return;
            }

            if (this.isAllowed(addrToCheck, freelist)) {
                try {
                    this.setState({mintingStatus: 1, mintError: null});
                    const proof = this.proofFromList(freelist);
                    const gasL = await contract.estimateGas.freeMint(this.state.FMcounter, proof, overrides);
                    overrides.gasLimit = this.increaseGasLimit(gasL);
                    const transaction = await contract.freeMint(this.state.FMcounter, proof, overrides);
                    await transaction.wait();
                    this.setState({mintingStatus: 2, mintError: null});
                } catch (err) {
                    console.log(err);
                    this.setState({mintingStatus: 3, mintError: this.readableError(err)});
                }
            }
        }
    }

    async mint() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if (this.checkNetwork(provider)) {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(address, Contract.abi, signer);

            try {
                this.setState({mintingStatus: 1, mintError: null});
                let overrides = {
                    value: this.PRICE(this.state.counter)
                }
                const gasL = await contract.estimateGas.publicMint(this.state.counter, overrides);
                overrides.gasLimit = this.increaseGasLimit(gasL);
                const transaction = await contract.publicMint(this.state.counter, overrides);
                await transaction.wait();
                this.setState({mintingStatus: 2, mintError: null});
            } catch (err) {
                console.log(err);
                this.setState({mintingStatus: 3, mintError: this.readableError(err)});
            }
        }
    }

    smartEth() {
        const e = this.state.selectedAddress.substr(this.state.selectedAddress.length - 6);
        const s = this.state.selectedAddress.substring(0, 6);
        return s + '...' + e;
    }

    canFreemint() {
        const addrToCheck = this.state.selectedAddress;
        const tab = [];
        freelist.map(a => {
            tab.push(a.address)
        });
        freelist_iron.map(a => {
            tab.push(a.address)
        });
        freelist_bronze.map(a => {
            tab.push(a.address)
        });
        freelist_silver.map(a => {
            tab.push(a.address)
        });
        freelist_gold.map(a => {
            tab.push(a.address)
        });
        freelist_platinum.map(a => {
            tab.push(a.address)
        });
        const leaves = tab.map(v => keccak256(v));
        const tree = new MerkleTree(leaves, keccak256, {sort: true});
        const leaf = keccak256(addrToCheck);
        const proof = tree.getHexProof(leaf);
        return tree.verify(proof, leaf, tree.getHexRoot());
    }

    proofFromList(list) {
        const addrToCheck = this.state.selectedAddress;
        let tab = [];
        list.map(a => {
            tab.push(a.address)
        });
        const leaves = tab.map(v => keccak256(v));
        const tree = new MerkleTree(leaves, keccak256, {sort: true});
        const leaf = keccak256(addrToCheck);
        return tree.getHexProof(leaf);
    }

    renderEnd() {
        let button;
        button = <div key="gsis"><h3>Sale is over</h3>
            <button className="btn gold-btn" onClick={this.handleCollectionClick}>VIEW COLLECTION
            </button>
        </div>;
        return [button];
    }

    renderStep0() {
        const selectedAddress = this.state.selectedAddress;
        let button;
        if (selectedAddress) {
            button = <div key="step0">
                <h3>You are ready to mint</h3>
                <label>With wallet : {this.smartEth()}</label></div>;
        } else {
            button = <button key="step0btn" className="btn gold-btn" onClick={this.handleMetamaskClick}>CONNECT
                METAMASK</button>;
        }
        return [button];
    }

    renderWhitelist(label, list, mintFn) {
        const selectedAddress = this.state.selectedAddress;
        let button;
        let fremint = '';
        if (selectedAddress) {
            if (this.isAllowed(selectedAddress, list) || this.isAllowed(selectedAddress, oglist)) {
                button = <div key="mint">
                    <h3>{label} sale</h3>
                    <div>
                        <button className="btn gold-btn" onClick={mintFn}>MINT</button>
                        <div className="counter">
                            <span className="action down" onClick={this.downAction}><span>-</span></span>
                            <span className="value">{this.state.counter}</span>
                            <span className="action up" onClick={this.upAction}><span>+</span></span>
                        </div>
                    </div>
                    <label>With wallet : {this.smartEth()}</label>
                </div>;
            } else {
                button = <div key="mint">
                    <h3>{label} sale</h3>
                    <div>
                        <button className="btn" disabled>NOT ALLOWED</button>
                    </div>
                    <label>With wallet : {this.smartEth()}</label>
                </div>;
            }
            if (this.canFreemint()) {
                fremint = <div key="freemint">
                    <div className="sep">or</div>
                    <button className="btn gold-btn" onClick={this.handleFreeMintClick}>FREE MINT</button>
                    <div className="counter">
                        <span className="action down" onClick={this.FMdownAction}><span>-</span></span>
                        <span className="value">{this.state.FMcounter}</span>
                        <span className="action up" onClick={this.FMupAction}><span>+</span></span>
                    </div>
                    <label>With wallet : {this.smartEth()}</label>
                </div>;
            }
        } else {
            button = <div key="mint-connect"><h3>{label} sale</h3>
                <button className="btn gold-btn" onClick={this.handleMetamaskClick}>CONNECT METAMASK</button>
            </div>;
        }
        const creditCard = <button key="cc" className="btn white-btn" onClick={this.handleCardClick}>Buy via credit
            card</button>;
        const sep = <div key="sep" className="sep">or</div>;
        return [button, fremint];
    }

    renderAllowlist(label, list, mintFn) {
        const selectedAddress = this.state.selectedAddress;
        let button;
        if (selectedAddress) {
            if (this.isAllowed(selectedAddress, list)) {
                button = <div key="mint">
                    <h3>{label} sale</h3>
                    <div>
                        <button className="btn gold-btn" onClick={mintFn}>MINT</button>
                        <div className="counter">
                            <span className="action down" onClick={this.downAction}><span>-</span></span>
                            <span className="value">{this.state.counter}</span>
                            <span className="action up" onClick={this.upAction}><span>+</span></span>
                        </div>
                    </div>
                    <label>With wallet : {this.smartEth()}</label>
                </div>;
            } else {
                button = <div key="mint">
                    <h3>{label} sale</h3>
                    <div>
                        <button className="btn" disabled>NOT ALLOWED</button>
                    </div>
                    <label>With wallet : {this.smartEth()}</label>
                </div>;
            }

        } else {
            button = <div key="mint-connect"><h3>{label} sale</h3>
                <button className="btn gold-btn" onClick={this.handleMetamaskClick}>CONNECT METAMASK</button>
            </div>;
        }
        return [button];
    }

    renderMint() {
        const selectedAddress = this.state.selectedAddress;
        let button;
        if (selectedAddress) {
            button = <div key="mint">
                <button className="btn gold-btn" onClick={this.handleMintClick}>MINT</button>
                <div className="counter">
                    <span className="action down" onClick={this.downAction}><span>-</span></span>
                    <span className="value">{this.state.counter}</span>
                    <span className="action up" onClick={this.upAction}><span>+</span></span>
                </div>
                <label>With wallet : {this.smartEth()}</label></div>;
        } else {
            button = <button className="btn gold-btn" onClick={this.handleMetamaskClick}>CONNECT METAMASK</button>;
        }

        const creditCard = <button key="cc" className="btn white-btn" onClick={this.handleCardClick}>Buy via credit
            card</button>;
        const sep = <div key="sep" className="sep">or</div>;
        return [button, sep, creditCard];
    }

    render() {
        let content = '';
        if (this.state.mintingStatus !== 0) {
            switch (this.state.mintingStatus) {
                case 1:
                    content = "LOADING..."
                    break;
                case 2:
                    content = "SUCCESSFULLY MINTED !"
                    break;
                case 3:
                    content = <div className="error">
                        <div>ERROR WHILE MINTING</div>
                        <div>{this.state.mintError}</div>
                        <button key="cc" className="btn white-btn" onClick={this.refreshPage}>Refresh</button>
                    </div>
                    break;
                default:
                    content = "PLEASE REFRESH THE PAGE"
                    break;
            }

        } else {
            const now = new Date().getTime();
            if (now < WLSaleDate) {
                content = this.renderStep0();
            } else if (now < ALSaleDate) {
                content = this.renderWhitelist('Whitelist', whitelist, this.handleWhitelistMintClick);
            } else if (now < SaleDate) {
                content = this.renderAllowlist('Allowlist', allowList, this.handleAllowlistMintClick);
            } else if (now < EndSaleDate) {
                content = this.renderMint();
            } else {
                content = this.renderEnd();
            }
        }

        return (
            <article>
                <header>
                    <h1>"Like a Brush"</h1>
                    <h2>by Julien Durix</h2>
                </header>
                <main>
                    <div className="row">
                        {content}
                    </div>
                </main>
                <footer>
                    <div className="footer-logo"></div>
                    <div className="copyright" onClick={this.openLegals}>JULIEN DURIX COPYRIGHT 2022 / NFT LICENCE / PRIVACY / TERMS</div>
                </footer>
                <WinterCheckout
                    projectId={this.state.winterId}
                    production={true}
                    showModal={this.state.showWinter}
                    // pass in a function to be called when a successful purchase happens
                    onSuccess={() => {
                        this.setState({showWinter: false, mintingStatus: 2, mintError: null});
                    }}
                    // pass in a function to be called when the modal is closed
                    onClose={() => {
                        this.setState({showWinter: false, mintingStatus: 0, mintError: null});
                    }}
                />
            </article>
        );
    }
}

export default Home;

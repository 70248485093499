import {React, useContext} from 'react';
import Home from './Home';
import Reveal from './Reveal';

import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Reveal />}>
        </Route>
        <Route exact path="/reveal" element={<Reveal />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

import { render } from '@testing-library/react';
import { useState, React, Component } from 'react';
import {ethers} from 'ethers';
import Contract from './abi/LikeABrush.json';
import ContractBurn from './abi/PostBurn.json';
import { cp } from 'fs';
import './Reveal.css';

const collectionURL = "https://opensea.io/collection/like-a-brush-by-julien-durix";
const address ="0x6B6C66C7128f2585D8AAd8e4EFdaC33dD3c376cC";
const addressBurn ="0x604872A5BEd0B37845c1b4f8cc6761B938B36739";
const winterIds = [7092, 7092];
const networkId = "0x1";
class Reveal extends Component {

    constructor(props) {
        super(props);
   
        this.openLegals = this.openLegals.bind(this);
        this.handleFakeMintClick = this.handleFakeMintClick.bind(this);
        this.handleCheckApprovalClick = this.handleCheckApprovalClick.bind(this);
        this.handleClickReveal = this.handleClickReveal.bind(this);
        this.refreshState = this.refreshState.bind(this);
        this.handleMetamaskClick = this.handleMetamaskClick.bind(this);
        let addr = null;

        if (typeof window.ethereum !== 'undefined') {
            addr = window.ethereum.selectedAddress;
        }
        this.state = {
            winterId: winterIds[0],
            selectedAddress: addr,
            counter: 1,
            revealNumber: null,
            disabled: true,
            metamaskConnected: false,
            text: null
        };
        
    }

    increaseGasLimit(estimatedGasLimit) {
        return estimatedGasLimit.mul(115).div(100) // increase by 15%
    }

    openLegals(){
        window.open('https://mint.juliendurixnft.io/legals.html', '_blank').focus();
    }

    handleFakeMintClick() {
       this.FakeMint();
    }

    componentDidMount() {
        this.refreshState();
    }

    handleMetamaskClick() {
        this.requestAccount();
    }


    async refreshState() {
        if (this.state.selectedAddress) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
           // if (this.checkNetwork(provider)) {
             
           // }
        }
    }

    readableError(raw) {
        var rs = raw.toString();
        var ind1 = rs.indexOf('{"code"');
        var ind2 = rs.indexOf('}, method="estimateGas');
        var newStr = rs.substring(ind1, ind2 + 1);
        try {
            var jErr = JSON.parse(newStr);
            return jErr.message;
        } catch (e) {
            return raw.message;
        }
    }

    async requestAccount() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
            this.setState({selectedAddress: accounts[0]});
            this.setState({metamaskConnected: true})
            this.refreshState();
        }
    }

    async handleCheckApprovalClick(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(address, Contract.abi, signer);
        const transaction = await contract.isApprovedForAll(this.state.selectedAddress,addressBurn);
        if(transaction){
            this.setState({disabled: false});
        }else{
            const transaction = await contract.setApprovalForAll(addressBurn,true);
            transaction.wait();
            const transactionVerifier = await contract.isApprovedForAll(this.state.selectedAddress,addressBurn);
            if(transactionVerifier){
                this.setState({disabled: false});
            }
        }

    }


    async handleClickReveal(){
        if (this.state.revealNumber) {
     
            try {

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            let overrides = {}

            const contract = new ethers.Contract(addressBurn, ContractBurn, signer);
            const gasL = await contract.estimateGas.JulienDurixReveal(this.state.revealNumber, this.state.selectedAddress, overrides);
            overrides.gasLimit = this.increaseGasLimit(gasL);
            const transaction = await contract.JulienDurixReveal(this.state.revealNumber, this.state.selectedAddress, overrides);
            await transaction.wait();
            this.setState({text:"Reveal Success !"})
            } catch (err) {
                console.log(err);
                this.setState({text:this.readableError(err)})
            }

        } else {
            this.setState({revealNumber: 0});
        }
    }

    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({ revealNumber: e.target.value});
    }
    async FakeMint(){
        let p = 1 * 0.28;
       
        let overrides = {
            value:  ethers.utils.parseEther(p.toFixed(2).toString())
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(address, Contract.abi, signer);
        const gasL = await contract.estimateGas.publicMint(this.state.counter, overrides);
        overrides.gasLimit = this.increaseGasLimit(gasL);
        const transaction = await contract.publicMint(this.state.counter, overrides);
        await transaction.wait();
        
    }
    render() {
        return(
        <div>
            <article>
                <header>
                    <h1>"Like a Brush"</h1>
                    <h2>by Julien Durix</h2>
                </header>
                <main>
                    { this.state.metamaskConnected ? 
                          <div className="row">
                             { this.state.disabled ? 
        
                                <div className="row">
                                    <button className="btn gold-btn" onClick={this.handleCheckApprovalClick}>CHECK APPROVAL</button>
                                </div> 
                                :
                                <div></div>
                                 }
            
                                { this.state.disabled ? 
                                     <div></div>
                                : <div className="row">
                                <input type="number" disabled={this.state.disabled}  onChange={ this.handleChange } min={0} max={727} className="btn gold-input"></input>
                                <button className="btn gold-btn" disabled={this.state.disabled} onClick={this.handleClickReveal}>REVEAL</button>
                                { this.state.text != "" ? 
                                <div className='text text-center error-text'>{this.state.text}</div>
                                :
                                <div></div>
                                }
                                
                                </div> 
                                
                                
                                }
                            </div>
                    : 
                    <div className="row">
                        <button className="btn gold-btn" onClick={this.handleMetamaskClick}>CONNECT METAMASK</button>
                    </div>
                          
                    }
                   


                </main>
                <footer>
                    <div className="footer-logo"></div>
                    <div className="copyright" onClick={this.openLegals}>JULIEN DURIX COPYRIGHT 2022 / NFT LICENCE / PRIVACY / TERMS</div>
                </footer>
            </article>
        </div>
           
        )
    }
}



export default Reveal;